@import './variables.scss';
@import '../button.scss';

* {
  font-family: Montserrat, sans-serif;
}

h2, h3, p, ul{
  margin: 0;
  padding: 0;
}

.container {
  max-width: 1400px;
  margin: 0 auto;
}

@media (max-width: 1600px) {

  .container {
    max-width: 1200px;
  }
}

@media (max-width: 1400px) {

  .container {
    max-width: 1000px;
  }
}

@media (max-width: 1099px) {

  .container {
    padding: 0 100px;
  }
}

@media (max-width: 899px) {

  .container {
    padding: 0 50px;
  }
}

@media (max-width: 550px) {

  .container {
    padding: 0 30px;
  }
}

@media (max-width: 400px) {

  .container {
    padding: 0 20px;
  }
}