@import './courses/variables.scss';

.button {
  padding: 25px 57px;
  height: 80px;
  background: linear-gradient(180deg, #3697D1 0%, rgba(29, 73, 103, 0.29) 100%), #5689B4;
  box-shadow: 0px 4px 14px rgba(81, 126, 173, 0.2);
  border-radius: 40px;
  border: none;
  outline: none;
  cursor: pointer;

  font-size: 24px;
  font-weight: bold;
  letter-spacing: 0.05em;
  text-transform: uppercase;

  color: #fff;

  transition: color 0.3s, background-color 0.3s, box-shadow 0.3s;

  &:hover {
    background: #FFFFFF;
    border: 2px solid $mainColor;
    box-shadow: 0px 4px 14px rgba(81, 126, 173, 0.2);
    color: $mainColor;
  }

  @media (max-width: 1099px) {
    font-size: 16px;
    height: 60px;
    padding: 20px 48px;
  }
  @media (max-width: 500px) {
    font-size: 14px;
    padding: 20px 0;
    width: 100%;
  }
}